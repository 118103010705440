import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { navigate } from "gatsby";
import { PageCard, DocCard } from "ducks/Search";
import { Container, Section, SectionBreak } from "ducks/Search/styles";
import ViewMore from "../../ducks/Search/ViewMore";

export default function SearchPage(props) {
  const searchResults = props?.location?.state?.searchResults;
  const [pageResults, setPageResults] = useState([]);
  const [documentResults, setDocumentResults] = useState([]);
  //const [productResults, setProductResults] = useState([]);

  const parseResults = (results) => {
    const _pageResults =
      results?.filter(({ fields }) => fields?.type[0] === "pages") ?? [];
    /* const _productResults =
      results?.filter(({ fields }) => fields?.type[0] === "product") ?? []; */
    const _documentationResults =
      results?.filter(({ fields }) => fields?.type[0] === "document") ?? [];

    setPageResults(_pageResults);
    //setProductResults(_productResults);
    setDocumentResults(_documentationResults);
  };

  useEffect(() => {
    if (searchResults) {
      parseResults(searchResults);
    }
  }, [searchResults]);

  const handleNavigateToMoreResults = (searchResults, resultType) =>
    navigate("/search/results", {
      state: { searchResults, resultType },
    });

  const pageResultsContent =
    pageResults?.length > 0 ? (
      <Section>
        <h3>PAGES</h3>
        {pageResults
          .filter(({ fields }, index) => index < 3 && fields?.url)
          .map((results, index) => (
            <PageCard
              key={`page-result-card-${results?.fields?.title}-${index}`}
              {...results}
            />
          ))}
        <ViewMore
          onClick={() => handleNavigateToMoreResults(pageResults, "pages")}
          totalResults={pageResults.length}
          numberShowing={3}
          label="VIEW MORE PAGES"
        />
      </Section>
    ) : null;

  const documentResultsContent =
    documentResults?.length > 0 ? (
      <Section>
        <h3>DOCUMENTATION</h3>
        {documentResults
          .filter((_, index) => index < 3)
          .map((result, index) => {
            return (
              <DocCard key={`${index}-${result?.fields?.title}`} {...result} />
            );
          })}
        <ViewMore
          onClick={() =>
            handleNavigateToMoreResults(documentResults, "documents")
          }
          totalResults={documentResults.length}
          numberShowing={3}
          label="VIEW MORE DOCUMENTATION"
        />
      </Section>
    ) : null;

  return (
    <Layout title="search results">
      <Container>
        {/*  <Section>
          <h3>PRODUCTS</h3>
          {productResults}
          <h6 className="view-more">{"VIEW STORE >"}</h6>
        </Section>
        <SectionBreak /> */}
        {pageResultsContent}
        {pageResultsContent && documentResultsContent && <SectionBreak />}
        {documentResultsContent}
      </Container>
    </Layout>
  );
}
