import React from "react";

const ViewMore = ({
  totalResults,
  numberShowing,
  label = "VIEW MORE",
  onClick = () => {},
  disabled = false,
}) => {
  if (totalResults <= numberShowing) {
    return null;
  }

  return (
    <button
      onClick={onClick}
      className="view-more"
      disabled={disabled}
    >{`${label} (${totalResults - numberShowing}) >`}</button>
  );
};

export default ViewMore;
